<template>
  <div class="mb-8">
    <h1 class="text-2xl pt-8 pb-8 desktop:pb-16 desktop:text-4xl">
      7 Modes of <span class="bold">{{ currentKey }}</span> major scale
    </h1>
    <div class="desktop:flex">
      <div class="desktop:w-1/5 mb-12 desktop:mb-0">
        <div class="pb-4">
          <h3 class="font-bold">All 12 notes:</h3>
          <div>
            <span v-for="(note, index) in notes" :key="index">{{ note }} </span>
          </div>
        </div>
        <div class="pb-4">
          <h3 class="font-bold">Major scale formula:</h3>
          <p>W W H W W W H</p>
        </div>
        <div class="pb-4">
          <h3 class="font-bold">Major scale notes:</h3>
          <div>
            <span v-for="(note, index) in currentScale" :key="index"
              >{{ note }}
            </span>
          </div>
        </div>
        <div class="pb-4">
          <h3 class="font-bold">Major scale chords:</h3>
          <div>
            <span v-for="(chord, index) in currentChords" :key="index"
              >{{ chord }}
            </span>
          </div>
        </div>
        <div>
          <h3 class="font-bold">Change key:</h3>
          <select
            v-model="currentKey"
            class="border-2 border-orange-500 rounded-xl outline-none p-1 mt-2 bg-white"
          >
            <option v-for="(note, index) in notes" :value="note" :key="index">{{
              note
            }}</option>
          </select>
        </div>
      </div>
      <div class="desktop:w-2/5 mb-12 desktop:mb-0 px-8">
        <div class="text-center">
          Current mode of {{ currentKey }} major scale: :
          <span class="font-bold">{{ currentMode.order }}</span> -
          <span class="font-bold text-orange-600">{{ currentMode.name }}</span>
        </div>
        <div class="text-center">
          <div class="relative mt-16 inline-block mx-auto">
            <div>
              <span class="chord chord-1">1</span>
              <span class="chord chord-2">2</span>
              <span class="chord chord-3">3</span>
              <span class="chord chord-4">4</span>
              <span class="chord chord-5">5</span>
              <span class="chord chord-6">6</span>
              <span class="chord chord-7">7</span>
            </div>
            <ul ref="circle" class="circle-container">
              <li v-for="(note, index) in currentChords" :key="index">
                <span ref="circleItem">{{ note }} </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex justify-center mt-10">
          <div
            @click="prevMode()"
            id="prev"
            class="w-12 p-2 m-2 bg-white cursor-pointer text-center border-2 border-orange-500 rounded-xl"
          >
            &#8592;
          </div>
          <div
            @click="nextMode()"
            id="next"
            class="w-12 p-2 m-2 bg-white cursor-pointer text-center border-2 border-orange-500 rounded-xl"
          >
            &#8594;
          </div>
        </div>
      </div>
      <div class="desktop:w-2/5">
        <div>
          <h3 class="text-2xl">Jam scale for {{ currentMode.name }} mode</h3>
          <div v-if="currentMode.order == 'First'">
            <div class="mb-4">
              Bright, happy vibe. Start on <strong>I</strong> chord, go to
              <strong>IV</strong> or <strong>V</strong> chord, and end on
              <strong>I</strong> chord. Use all other chords for transition. For
              better understanding of this mode, watch
              <a
                href="https://www.youtube.com/watch?v=M8eItITv8QA&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=gbNLhx2eCXs&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/1.jpg" alt="Ionian" />
          </div>
          <div v-else-if="currentMode.order == 'Second'">
            <div class="mb-4">
              Not as dark as minor, not as bright as major. Used in funk a lot.
              Use <strong>i</strong> chord and <strong>IV</strong> chord to get
              this mode vibe. For better understanding of this mode, watch
              <a
                href="https://www.youtube.com/watch?v=hyZPcYf1Pe4&t=8s&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=_r6ftqp1bkA&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/2.jpg" alt="Dorian" />
          </div>
          <div v-else-if="currentMode.order == 'Third'">
            <div class="mb-4">
              Dark, evil, dramatic vibe. Always stress root, hangout on tonic -
              <strong>i</strong> chord, use <strong>II</strong> chord and
              <strong>vii</strong> chord for transition. For better
              understanding of this mode, watch
              <a
                href="https://www.youtube.com/watch?v=ZnoKgWnMEq8&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=cSSAJ0goWiE&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/3.jpg" alt="Phrygian" />
          </div>
          <div v-else-if="currentMode.order == 'Forth'">
            <div class="mb-4">
              Dreamy vibe. Unstable mode, wants to resolve to V chord, avoid
              resolving, make it unstable, always hangout on
              <strong>I</strong> chord, for a short amount of time bring
              <strong>II</strong> and <strong>vii</strong> chords. Use 1 3 4 5
              notes to get the feel. For better understanding of this mode,
              watch
              <a
                href="https://www.youtube.com/watch?v=Ou_Z9ol8r0I&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=Y06WBKbm8kI&ab_channel=MusicTheoryForGuitar"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/4.jpg" alt="Lydian" />
          </div>
          <div v-else-if="currentMode.order == 'Fifth'">
            <div class="mb-4">
              Classic rock, high energy, fun, blues vibe. For better
              understanding of this mode, watch
              <a
                href="https://www.youtube.com/watch?v=5kYsVpLtOmc&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=39iZqrZf66o&ab_channel=SignalsMusicStudio"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/5.jpg" alt="Mixolydian" />
          </div>
          <div v-else-if="currentMode.order == 'Sixth'">
            <div class="mb-4">
              Sad, melancholic vibe. For better understanding of this mode,
              watch
              <a
                href="https://www.youtube.com/watch?v=q9JJVu2gazg&ab_channel=MartyMusic"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >,
              <a
                href="https://www.youtube.com/watch?v=TXiDnoB6BRs&ab_channel=RedHookGuitar"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                target="_blank"
                class="text-blue-700 underline"
                href="https://www.youtube.com/watch?v=Bz_q96JhSOg&ab_channel=SignalsMusicStudio"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/6.jpg" alt="Aeolian" />
          </div>
          <div v-else-if="currentMode.order == 'Seventh'">
            <div class="mb-4">
              Dark, unstable, disconnected, dissonance vibe. For better
              understanding of this mode, watch
              <a
                href="https://www.youtube.com/watch?v=QOW5QeZg8F0&ab_channel=SeanDaniel"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              and
              <a
                href="https://www.youtube.com/watch?v=Q6JBsOzOFaQ&ab_channel=DavidBennettPiano"
                target="_blank"
                class="text-blue-700 underline"
                >this</a
              >
              tutorials.
            </div>
            <img class="block" src="./../assets/7.jpg" alt="Locrian" />
          </div>
          <div class="mt-3">
            <div class="mb-2 flex items-center">
              <div class="circle"></div>
              <span>- Root note</span>
            </div>
            <div class=" flex items-center">
              <div class="circle circle--green"></div>
              <span>- Chord note</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-img mt-8 mx-auto">
      <img class="block w-full" src="./../assets/main.jpg" alt="main" />
      <div ref="overlay" class="overlay"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Teorija",
  data() {
    return {
      notes: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"],
      currentKey: "C",
      currentScale: [],
      currentChords: [],
      modes: [
        {
          name: "Ionian",
          order: "First",
        },
        {
          name: "Dorian",
          order: "Second",
        },
        {
          name: "Phrygian",
          order: "Third",
        },
        {
          name: "Lydian",
          order: "Forth",
        },
        {
          name: "Mixolydian",
          order: "Fifth",
        },
        {
          name: "Aeolian",
          order: "Sixth",
        },
        {
          name: "Locrian",
          order: "Seventh",
        },
      ],
      currentMode: "",
      rotate: 0,
    };
  },
  watch: {
    currentKey: function(val) {
      this.changeRoot(val);
    },
    currentMode: function(val) {
      this.moveOverlay(val);
    },
  },
  mounted() {
    this.changeRoot("C");
  },
  methods: {
    moveOverlay: function(val) {
      const overlay = this.$refs["overlay"];
      console.log(val);
      if (val.order === "First") {
        overlay.style.left = 0;
        overlay.style.width = "calc(100% / 3.85)";
      } else if (val.order === "Second") {
        overlay.style.left = "12%";
        overlay.style.width = "calc(100% / 3.05)";
      } else if (val.order === "Third") {
        overlay.style.left = "31%";
        overlay.style.width = "calc(100% / 3.9)";
      } else if (val.order === "Forth") {
        overlay.style.left = "31%";
        overlay.style.width = "calc(100% / 3.9)";
      } else if (val.order === "Fifth") {
        overlay.style.left = "43%";
        overlay.style.width = "calc(100% / 3.1)";
      } else if (val.order === "Sixth") {
        overlay.style.left = "55.5%";
        overlay.style.width = "calc(100% / 3.1)";
      } else if (val.order === "Seventh") {
        overlay.style.left = "74%";
        overlay.style.width = "calc(100% / 3.9)";
      }
    },
    modeName: function(direction) {
      const index = this.modes.indexOf(this.currentMode);
      if (direction === "next") {
        if (this.modes[this.modes.length - 1] != this.currentMode) {
          this.currentMode = this.modes[index + 1];
        } else {
          this.currentMode = this.modes[0];
        }
      } else {
        if (index == 0) {
          this.currentMode = this.modes[this.modes.length - 1];
        } else {
          this.currentMode = this.modes[index - 1];
        }
      }
    },
    prevMode: function() {
      const circle = this.$refs["circle"];
      const circleItems = this.$refs["circleItem"];
      this.rotate += 360 / 7;

      circle.style.transform = `rotate(${this.rotate}deg)`;
      circleItems.forEach((item) => {
        item.style.transform = `rotate(${-this.rotate}deg)`;
      });
      this.modeName("prev");
    },
    nextMode: function() {
      const circle = this.$refs["circle"];
      const circleItems = this.$refs["circleItem"];
      this.rotate -= 360 / 7;

      circle.style.transform = `rotate(${this.rotate}deg)`;
      circleItems.forEach((item) => {
        item.style.transform = `rotate(${-this.rotate}deg)`;
      });
      this.modeName("next");
    },
    changeRoot: function(val) {
      const index = this.notes.indexOf(val);
      const p1 = this.notes.slice(0, index);
      const p2 = this.notes.slice(index);
      const newNotes = p2.concat(p1);
      this.createMajorScale(newNotes);
      this.createMajorScaleChords(newNotes);
      this.currentMode = this.modes[0];

      this.$nextTick(function() {
        const circle = this.$refs["circle"];
        const circleItems = this.$refs["circleItem"];
        this.rotate = 0;

        circle.style.transform = `rotate(${this.rotate}deg)`;
        circleItems.forEach((item) => {
          item.style.transform = `rotate(${-this.rotate}deg)`;
        });
      });
    },
    createMajorScale: function(notes) {
      const scale = [];
      scale.push(notes[0]);
      scale.push(notes[2]);
      scale.push(notes[4]);
      scale.push(notes[5]);
      scale.push(notes[7]);
      scale.push(notes[9]);
      scale.push(notes[11]);
      this.currentScale = scale;
    },
    createMajorScaleChords: function(notes) {
      const chords = [];
      chords.push(notes[0]);
      chords.push(notes[2] + "m");
      chords.push(notes[4] + "m");
      chords.push(notes[5]);
      chords.push(notes[7]);
      chords.push(notes[9] + "m");
      chords.push(notes[11] + "dim");
      this.currentChords = chords;
    },
  },
};
</script>

<style lang="scss">
a {
  font-weight: 700;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-right: 5px;
  display: inline-block;
  background: #fff;
  &--green {
    background: #2a8c3b;
    border: none;
  }
}
.main-img {
  position: relative;
  max-width: 834px;
  width: 100%;
  .overlay {
    position: absolute;
    content: "";
    transition: 0.15s;
    top: 0;
    left: 0;
    width: calc(100% / 5.4);
    height: 100%;
    background: #ff57222b;
  }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  padding: 0;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -($item-size / 2);
    width: $item-size;
    height: $item-size;

    $angle: (360 / $item-count);
    $rot: 268;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.circle-container {
  @include on-circle($item-count: 7, $circle-size: 12em, $item-size: 3.5em);
  border: solid 4px tomato;
  transition: 0.15s;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    border-radius: 50%;
    filter: grayscale(100%);
    border: solid 2px tomato;
    transition: 0.15s;
    background: #fff;
    &:hover {
      filter: grayscale(0);
    }
  }
  span {
    transition: 0.5s;
  }
}

.chord {
  position: absolute;
  &-1 {
    top: -55px;
    right: 95px;
  }
  &-2 {
    top: 11px;
    right: -25px;
  }
  &-3 {
    top: 102px;
    right: -48px;
  }
  &-4 {
    top: 195px;
    right: 7px;
  }
  &-5 {
    top: 199px;
    right: 167px;
  }
  &-6 {
    top: 107px;
    left: -48px;
  }
  &-7 {
    top: 7px;
    left: -27px;
  }
}
</style>
