<template>
  <div id="app">
    <div class="container">
      <Teorija />
    </div>
  </div>
</template>

<script>
import Teorija from "./components/Teorija.vue";

export default {
  name: "App",
  components: {
    Teorija,
  },
};
</script>

<style lang="scss">
body {
  position: relative;
  height: 100vh;
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("assets/beethoven.jpeg");
    background-position: center;
    background-size: cover;
    z-index: -1;
    opacity: 0.2;
    @media (max-width: 900px) {
      opacity: 0.1;
    }
  }
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
</style>
